import React, { Suspense, useState } from "react";
import { theme } from "./theme";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Footer from "./components/Footer/Footer";
import PrimarySearchAppBar from "./components/AppBar/AppBar.component";

const MintPage = React.lazy(() => import("./pages/Mint/Mint.page"));
const FeedPage = React.lazy(() => import("./pages/Feed/Feed.page"));
const InscriptionPage = React.lazy(
  () => import("./pages/Inscription/Inscription.page")
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100vw",
          minHeight: "100vh",
          overflow: "auto",
          background: "#242424",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          }
        >
          <Router>
            <Container maxWidth="lg">
              <PrimarySearchAppBar />
              <Divider variant="middle" sx={{ height: "5rem" }} />
              <Routes>
                <Route path="/" element={<MintPage />} />
                <Route path="/feed" element={<FeedPage />} />
                <Route
                  path="/inscription/:transactionId"
                  element={<InscriptionPage />}
                />
                <Route path="/inscribe" element={<Navigate to="/" replace />} />
              </Routes>
              <Divider variant="middle" sx={{ height: "5rem" }} />
              <Typography textAlign={"center"}>
                This Project is still in beta, USE AT YOUR OWN RISK!!!, contact
                jc@everlabs.xyz with any questions
              </Typography>
              <Footer />
            </Container>
          </Router>{" "}
        </Suspense>
      </Box>
    </ThemeProvider>
  );
}

export default App;
