import { createTheme } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0000",
      dark: green["700"],
    },
    secondary: {
      main: "#CC5500",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        style: {
          color: "white",
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          // Full width
          width: "100%",

          // Transparent background
          backgroundColor: "transparent",

          // White border all around
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },

            "& input": {
              color: "white",
            },
          },

          // Label styles
          "& .MuiInputLabel-root": {
            color: "white",
            // Position the label at the top left
            transformOrigin: "top left",
            left: 0,
            top: 0,

            "&.Mui-focused": {
              color: "#CC5500",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "white", // White label
          width: "100%", // Full width
        },
        select: {
          color: "white", // White text
          "&:focus": {
            backgroundColor: "transparent", // Keep background transparent on focus
          },
        },
      },
    },
    // Style overrides for OutlinedInput
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "white", // White border always
          },
          "&:hover fieldset": {
            borderColor: "white !important", // Ensure white border on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "white !important", // Ensure white border on focus
          },
        },
        notchedOutline: {
          borderColor: "white !important", // Ensures white border always
        },
      },
    },
    // Style overrides for InputLabel
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "white", // White label
          "&.Mui-focused": {
            color: "#CC5500", // Red label on focus
          },
        },
      },
    },
  },
  typography: {
    fontFamily: `'Fira Code', monospace`,
    h3: {
      fontWeight: "600",
    },
  },
});
